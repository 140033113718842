.scoreboard {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  gap: 20px;
}

.score {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.score:first-of-type {
  flex-direction: row-reverse;
}

.score > div:first-child {
  text-transform: uppercase;
  font-variant: small-caps;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0 10px;
}

.score > div:last-child {
  font-size: 1.8rem;
  font-weight: bold;
  width: 100%;
}
