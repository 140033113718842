.settings {
  --max-width: 625px;

  max-width: var(--max-width);
  flex-basis: 33%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 0;
}

.index {
  font-size: 9px;
  position: absolute;
  font-weight: bold;
  bottom: 0;
  right: 0;
}

.trigger,
.triggerOpen {
  cursor: pointer;
  transition-duration: 250ms;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.triggerOpen {
  transform: rotate(35deg);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.modal {
  --max-width: 585px;

  position: fixed;
  width: 95vw;
  max-width: var(--max-width);
  max-height: 95vh;
  min-height: 300px;
  overflow-x: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  border: 3px solid #000;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1001;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@supports (backdrop-filter: blur()) {
  .modal {
    background: rgba(255, 255, 255, 0.35);
    backdrop-filter: blur(50px);
  }
}