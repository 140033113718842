.card {
  --card-color: rgba(0, 0, 0, 0.3);
  --card-transition-duration: 500ms;
  --card-perspective: 20cm;
  max-height: 200px;
  max-width: 125px;

  /* width: calc(100vw / 4 - 40px);
  height: calc((100vw / 4 - 40px) * 1.6); */

  background:
    linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
    linear-gradient(var(--card-color), var(--card-color));
  border: 3px solid var(--card-color);
  border-radius: 8px;
  backface-visibility: hidden;
  z-index: 1;
  position: relative;
}

@media screen and (min-width: 380px) {
  .card {
    width: calc(100vw / 4 - 40px);
    height: calc((100vw / 4 - 40px) * 1.6);
  }
}

.cardFront {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.cardBack {
  position: absolute;
  height: 100%;
  width: 100%;
  transform: rotateY(180deg);
  top: -3px;
  left: -3px;
  z-index: 0;
  backface-visibility: hidden;
  border-radius: 8px;
  border: 3px solid rgba(0, 0, 0, 0.8);

  background-color: #346;
  /* background-color: rgb(52, 115, 209); */
  /* background-color: rgb(209, 52, 52); */

  --cardBack-stripe-color1: rgba(255, 255, 255, 0);
  --cardBack-stripe-color2: rgba(255, 255, 255, 0.4);

  background-image:
    repeating-linear-gradient(
      53deg,
      var(--cardBack-stripe-color1),
      var(--cardBack-stripe-color1) 5%,
      var(--cardBack-stripe-color2) 5%,
      var(--cardBack-stripe-color2) 10%,
      var(--cardBack-stripe-color1) 10%
    ),
    repeating-linear-gradient(
      -53deg,
      var(--cardBack-stripe-color1),
      var(--cardBack-stripe-color1) 5%,
      var(--cardBack-stripe-color2) 5%,
      var(--cardBack-stripe-color2) 10%,
      var(--cardBack-stripe-color1) 10%
    );
}

.activeCard {
  box-shadow: 0 0 10px var(--card-color);
}

.card .halfFill {
  --stripe-width: 25%; /* 11px */
  --stripe-angle: -53deg;
  --stripe-color: rgba(255, 255, 255, 0.6);

  background-image: repeating-linear-gradient(
    var(--stripe-angle),
    #fff,
    #fff var(--stripe-width),
    var(--stripe-color) var(--stripe-width),
    var(--stripe-color) calc(var(--stripe-width) * 2),
    #fff calc(var(--stripe-width) * 2)
  );
}

.cardShapeRectangle,
.cardShapeOval,
.cardShapeDiamond {
  --border-color: #333;
  --background-color: #fff;
  width: 37.6%;
  height: 36%;
  border: 3px solid var(--border-color);
  background: var(--background-color);
}

.cardShapeRectangle {
  border-radius: 8px;
}

.cardShapeOval {
  border-radius: 50%;
}

.cardShapeDiamond {
  transform: rotate(45deg);
  border-radius: 8px;
}



.cardEnter {
  transform: rotateY(180deg);
}

.cardEnterActive {
  transform-style: preserve-3d;
  transform: perspective(var(--card-perspective)) rotateY(0);
  transition: var(--card-transition-duration);
}

.cardExit {
  transform: rotateY(0);
}

.cardExitActive {
  transform-style: preserve-3d;
  transform: perspective(var(--card-perspective)) rotateY(-180deg);
  transition: var(--card-transition-duration);
}

.cardAppear {
  transform: rotateY(-180deg);
}

.cardAppearActive {
  transform-style: preserve-3d;
  transform: perspective(var(--card-perspective)) rotateY(0);
  transition: var(--card-transition-duration);
}