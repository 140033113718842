.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.8);
  padding: 0.25rem 1rem;
}

.spacer {
  width: 0;
  flex-basis: 33%;
}