.board {
  --columns: 4;
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;

  max-width: 625px;
}

.feedback {
  margin: 3rem 0 1rem;
  text-align: center;
}

.validity {
  font-weight: bold;
}

.help {
  max-width: 560px;
  max-width: 100%;
  margin: auto;
}

.italic {
  font-style: italic;
}

@media (orientation: landscape) {
  .board {
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 200px);
  }
}