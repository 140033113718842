.App {
  margin: 0 auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100vh;
  width: 100vw;
  font-size: 16px;
}

button {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  color: #333;
  background-color: #eee;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 4px 8px;
  padding: 0.5rem 0.8rem;
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.8),
    inset 0 -1px 0 rgba(0, 0, 0, 0.3);
}

button:active {
  background-color: #ccc;
  box-shadow:
    inset 0 1px 0 rgba(0, 0, 0, 0.3),
    inset 0 -1px 0 rgba(255, 255, 255, 0.8);
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

/* @media (orientation: landscape) {
  .App {
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-items: flex-start;
  }
} */
